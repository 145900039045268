import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'
import moment from 'moment'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useExpensenotesList() {
  // Use toast
  const toast = useToast()

  const currencies = [
    { label: 'Euro (EUR)', value: 'EUR' },
    { label: 'Dollaro Americano (USD)', value: 'USD' },
    { label: 'Sterlina Inglese (GBP)', value: 'GBP' },
    { label: 'Franco Svizzero (CHF)', value: 'CHF' },
    { label: 'Corona Svedese (SEK)', value: 'SEK' },
    { label: 'Lev Bulgaro (LEV)', value: 'LEV' },
    { label: 'Leu Rumeno (LEU)', value: 'LEU' },
    { label: 'Baht Thailandese (THB)', value: 'THB' },
    { label: 'Renminbi Cinese (RMB)', value: 'RMB' },
    { label: 'Yen Giapponese (YEN)', value: 'YEN' },
  ]

  const currencyLabels = {
    EUR: 'Euro (EUR)',
    USD: 'Dollaro Americano (USD)',
    GBP: 'Sterlina Inglese (GBP)',
    CHF: 'Franco Svizzero (CHF)',
    SEK: 'Corona Svedese (SEK)',
    LEV: 'Lev Bulgaro (LEV)',
    LEU: 'Leu Rumeno (LEU)',
    THB: 'Baht Thailandese (THB)',
    RMB: 'Renminbi Cinese (RMB)',
    YEN: 'Yen Giapponese (YEN)',
  }

  const paymentMethods = [
    { label: 'Contanti', value: 'cash' },
    { label: 'Carta di Credito', value: 'credit_card' },
  ]

  const paymentMethodLabels = {
    cash: 'Contanti',
    credit_card: 'Carta di Credito',
  }

  const months = [
    'Gennaio',
    'Febbraio',
    'Marzo',
    'Aprile',
    'Maggio',
    'Giugno',
    'Luglio',
    'Agosto',
    'Settembre',
    'Ottobre',
    'Novembre',
    'Dicembre',
  ]
  const momentMonth = moment().format('M')
  const currentMonth = momentMonth - 1
  const currentMonthString = months[currentMonth]
  const minDay = moment().format('YYYY-MM-DD')

  const perPage = ref(1000)
  const totaleExpensenotes = ref(0)
  const currentPage = ref(1)
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)

  const fetchExpensenotes = () => {
    store
      .dispatch('app-expensenotes/fetchExpensenotes', {
        text_search: searchQuery.value,
        per_page: perPage.value,
        page: currentPage.value - 1,
        sort_by: sortBy.value,
        sort_direction: isSortDirDesc.value === true ? 'DESC' : 'ASC',
      })
      .then(response => {
        const expensenotes = response.data[0]
        const total = response.data[1]

        return expensenotes
      })
      .catch(error => {
        if (error === 401) {
          localStorage.removeItem('userData')
          store.commit('user/updateUserData', null)
          window.location.reload(true)
        }
      })
  }

  const types = ref(null)
  const _types = []
  store
    .dispatch('app-expensenotes/fetchExpensenoteTypes', {})
    .then(response => {
      response.data[0].forEach((type, i) => {
        const _type = {
          label: type.name.charAt(0).toUpperCase() + type.name.slice(1),
          value: type.name,
        }
        _types.push(_type)
      })
    })
    .catch(error => {
      if (error === 401) {
        localStorage.removeItem('userData')
        store.commit('user/updateUserData', null)
        router.replace({ name: 'auth-login' }).then(() => {
          toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Sessione scaduta',
              icon: 'AlertTriangleIcon',
              text: "Sessione scaduta, effettuare di nuovo l'accesso per utilizzare tutte le funzionalità",
              variant: 'danger',
            },
          })
        })
      }
    })
  types.value = _types

  const users = ref(null)
  const _users = []
  store
    .dispatch('app-user/fetchUsers', { per_page: 1000 })
    .then(response => {
      response.data[0].forEach((user, i) => {
        const _user = {
          label: `#${user.anagrafico_id} - ${user.display_name}`,
          value: user.id,
        }
        _users.push(_user)
      })
    })
    .catch(error => {
      if (error === 401) {
        localStorage.removeItem('userData')
        store.commit('user/updateUserData', null)
        router.replace({ name: 'auth-login' }).then(() => {
          toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Sessione scaduta',
              icon: 'AlertTriangleIcon',
              text: "Sessione scaduta, effettuare di nuovo l'accesso per utilizzare tutte le funzionalità",
              variant: 'danger',
            },
          })
        })
      }
    })
  users.value = _users

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleName = role => {
    if (role === 'user') return 'Utente'
    if (role === 'hr') return 'HR'
    if (role === 'reception_hub') return 'Reception HUB'
    if (role === 'reception_colle') return 'Reception COLLE'
    if (role === 'accountant') return 'Contabilità'
    if (role === 'admin') return 'Admin'
    return ''
  }

  const resolveUserRoleVariant = role => {
    if (role === 'user') return 'info'
    if (role === 'hr') return 'warning'
    if (role === 'reception_hub') return 'warning'
    if (role === 'reception_colle') return 'warning'
    if (role === 'accountant') return 'warning'
    if (role === 'admin') return 'danger'
    return 'danger'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'user') return 'UserIcon'
    if (role === 'hr') return 'UsersIcon'
    if (role === 'reception_hub') return 'UsersIcon'
    if (role === 'reception_colle') return 'UsersIcon'
    if (role === 'accountant') return 'UsersIcon'
    if (role === 'admin') return 'ServerIcon'
    return 'UserXIcon'
  }

  const expensenotesStatus = status => {
    if (status === 'approved') return 'Approvato'
    if (status === 'pending') return 'In attesa di approvazione'
    if (status === 'denied') return 'Rifiutato'
  }

  const expensenotesStatusColor = status => {
    if (status === 'approved') return 'text-success'
    if (status === 'pending') return 'text-secondary'
    if (status === 'denied') return 'text-danger'
  }

  const expensenotesStatusIcon = status => {
    if (status === 'approved') return 'CheckCircleIcon'
    if (status === 'pending') return 'PauseCircleIcon'
    if (status === 'denied') return 'XCircleIcon'
  }

  const parseDate = date => moment(date).format('DD/MM/YYYY')

  const parseCurrency = number => Number(number).toFixed(2)

  return {
    fetchExpensenotes,
    months,
    currentMonth,
    currentMonthString,
    minDay,
    types,
    users,
    currencies,
    currencyLabels,
    paymentMethods,
    paymentMethodLabels,
    perPage,
    currentPage,
    totaleExpensenotes,
    searchQuery,
    sortBy,
    isSortDirDesc,

    resolveUserRoleName,
    resolveUserRoleVariant,
    resolveUserRoleIcon,
    expensenotesStatus,
    expensenotesStatusColor,
    expensenotesStatusIcon,
    parseDate,
    parseCurrency,
  }
}
