<template>
  <div>
    <b-card>
      <b-row>
        <b-col
          cols="12"
          md="10"
          class="d-flex justify-content-center align-items-center"
        >
          <h1><feather-icon
            class="mr-2"
            icon="DollarSignIcon"
            size="25"
          />Note Spesa</h1>
        </b-col>
        <b-col
          cols="12"
          md="2"
          class="text-center"
        >
          <b-button
            variant="primary"
            @click="$bvModal.show('export-modal')"
          >
            Esporta CSV
          </b-button>
        </b-col>
      </b-row>
    </b-card>
    <b-card no-body>
      <div class="m-1">
        <!-- Table Top -->
        <b-row>
          <b-col
            cols="12"
            md="6"
            lg="4"
            class="mb-1"
          >
            <label class="d-flex justify-content-between">
              Tipo
              <b-badge
                variant="primary"
                :to="{ name: 'apps-expensenotes-types' }"
              > Gestisci Tipi </b-badge>
            </label>
            <v-select
              v-model="filterType"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="types"
              class="w-100"
              @input="refetchExpensenotes()"
            />
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
            class="mb-1"
          />
          <b-col
            cols="12"
            md="12"
            lg="4"
            class="text-right"
          >
            <b-button
              variant="primary"
              @click="$bvModal.show('create-modal')"
            >
              Nuova Nota Spesa
            </b-button>
          </b-col>
          <b-col
            cols="12"
            class="mt-3"
          >
            <b-carousel
              id="month-slider"
              ref="monthSlider"
              v-model="selectedMonth"
              :interval="0"
              controls
              background="#ababab"
              @sliding-start="handleSlideStart()"
            >
              <b-carousel-slide
                v-for="month in months"
                :key="month"
                img-blank
              ><h2>{{ month }} {{ currentYear }}</h2>
              </b-carousel-slide>
            </b-carousel>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-row>
      <b-col cols="1" />
      <b-col
        :key="listKey"
        cols="10"
      >
        <b-card
          v-for="expensenote in expensenotes"
          :key="expensenote.id"
          no-body
          style="border-radius: 30px"
        >
          <b-card-title
            class="d-flex justify-content-center align-items-center p-1"
            style="margin-bottom: 0"
          >
            <h2
              class="text-capitalize"
              style="margin-bottom: 0"
            >
              {{ expensenote.type }}
            </h2>
            <span
              class="text-capitalize ml-2"
              style="font-size: 14px"
            >
              {{ parseCurrency(expensenote.expense_amount) }}
              <span
                class="text-info"
                style="font-size: 14px"
              >{{ expensenote.currency }}</span>
              <small
                v-if="expensenote.refundable === 1"
                class="text-success ml-1"
              >(Rimborsabile)</small>
              <small
                v-if="expensenote.refundable === 0"
                class="text-danger ml-1"
              >(Non Rimborsabile)</small>
            </span>
          </b-card-title>
          <div
            class="d-flex justify-content-between align-items-center pl-3 pr-3 pb-1 pt-1"
            style="width: 100%; padding: 0 3rem"
          >
            <div class="d-flex align-items-center">
              <b-avatar
                size="30"
                :src="expensenote.user.avatar_file ? mediaEndpoint + expensenote.user.avatar_file : ''"
                :text="avatarText(expensenote.user.display_name)"
                :variant="`light-${resolveUserRoleVariant(expensenote.user.role_code)}`"
                :to="{ name: 'apps-users-view', params: { id: expensenote.user.id } }"
              />
              <b-link
                :to="{ name: 'apps-users-view', params: { id: expensenote.user.id } }"
                class="font-weight-bold d-block text-nowrap ml-2"
              >
                #{{ expensenote.user.anagrafico_id }} - {{ expensenote.user.display_name }}
              </b-link>
            </div>
            <div>
              <b-button
                v-b-toggle="'collapse-' + expensenote.id"
                pill
                size="sm"
                variant="primary"
              >
                Visualizza Dettagli
              </b-button>
              <b-button
                title="Modifica"
                size="sm"
                variant="warning"
                class="ml-1 rounded-circle"
                style="padding: 6px"
                @click="
                  updatedExpensenoteUser = {label: '#' + expensenote.user.anagrafico_id + ' - ' + expensenote.user.display_name, value: expensenote.user.id},
                  updatedExpensenoteType = {label: expensenote.type.charAt(0).toUpperCase() + expensenote.type.slice(1), value: expensenote.type}
                  updatedExpensenoteCurrency = {label: currencyLabels[expensenote.currency], value: expensenote.currency}
                  updatedExpensenotePaymentMethod = {label: paymentMethodLabels[expensenote.payment_method], value: expensenote.payment_method}
                  updatedExpensenoteRefundable = expensenote.refundable === 1 ? true : false
                  updatedAttachments = expensenote.attachments
                  updatedExpensenote = Object.assign({}, expensenote)
                  $bvModal.show('update-modal')
                "
              >
                <feather-icon
                  icon="Edit2Icon"
                  size="15"
                />
              </b-button>
              <b-button
                title="Elimina"
                size="sm"
                variant="danger"
                class="ml-1 rounded-circle"
                style="padding: 6px"
                @click="
                  $bvModal.show('delete-modal')
                  selectedExpensenote = expensenote.id
                "
              >
                <feather-icon
                  icon="Trash2Icon"
                  size="15"
                />
              </b-button>
            </div>
          </div>
          <b-collapse
            :id="'collapse-' + expensenote.id"
            class="mt-2 pl-3 pr-3"
          >
            <b-card>
              <b-card-text>
                <b-row>
                  <b-col
                    cols="12"
                    class="mb-2"
                  >
                    <h6>Oggetto:</h6>
                    <div
                      class="rounded-lg p-1"
                      style="background-color: #161d31"
                    >
                      <p class="m-0">
                        {{ expensenote.subject }}
                      </p>
                    </div>
                  </b-col>
                  <b-col
                    cols="6"
                    class="mb-2"
                  >
                    <h6>Spesa:</h6>
                    <span>
                      {{ parseCurrency(expensenote.expense_amount) }} <span class="text-info">{{ expensenote.currency }}</span>
                    </span>
                  </b-col>
                  <b-col
                    v-if="expensenote.refundable"
                    cols="6"
                    class="mb-2"
                  >
                    <h6>Rimborso:</h6>
                    <span>
                      {{ parseCurrency(expensenote.refund_amount) }} <span class="text-info">{{ expensenote.currency }}</span>
                    </span>
                  </b-col>
                  <b-col
                    cols="6"
                    class="mb-2"
                  >
                    <h6>Metodo di Pagamento:</h6>
                    <span>
                      {{ paymentMethodLabels[expensenote.payment_method] }}
                    </span>
                  </b-col>
                  <b-col
                    v-if="expensenote.attachments && expensenote.attachments.length > 0"
                    cols="12"
                    class="mb-2"
                  >
                    <h6>Allegati:</h6>
                    <span style="font-size: 16px">
                      <b-badge
                        v-for="file in expensenote.attachments"
                        :key="file.file_path"
                        class="mr-2 pr-1 pl-1 mb-1"
                        variant="primary"
                        @click="downloadFile(file)"
                      >
                        <feather-icon icon="PaperclipIcon" />
                        {{ file.name }}
                      </b-badge>
                    </span>
                  </b-col>
                  <b-col
                    v-if="expensenote.description"
                    cols="12"
                  >
                    <h6>Descrizione:</h6>
                    <div
                      class="rounded-lg p-1"
                      style="background-color: #161d31"
                    >
                      <p class="m-0">
                        {{ expensenote.description }}
                      </p>
                    </div>
                  </b-col>
                </b-row>
              </b-card-text>
            </b-card>
          </b-collapse>
          <template #footer>
            <p
              v-if="expensenote.date_time"
              class="mb-0"
            >
              {{ parseDate(expensenote.date_day) }} - {{ expensenote.date_time.substring(0,5) }}
            </p>
            <p
              v-if="!expensenote.date_time"
              class="mb-0"
            >
              {{ parseDate(expensenote.date_day) }}
            </p>
          </template>
        </b-card>
      </b-col>
    </b-row>
    <b-modal
      id="create-modal"
      title="Nuova Nota Spesa"
      size="lg"
      footer-class="d-flex justify-content-end"
      centered
    >
      <template #modal-header="{ close }">
        <h5 class="mb-0">
          Nuova Nota Spesa
        </h5>
        <feather-icon
          icon="XIcon"
          size="18"
          @click="close()"
        />
      </template>
      <template>
        <b-form>
          <b-row class="mt-2 mb-2">
            <b-col
              cols="12"
              lg="6"
              class="mb-1"
            >
              <b-form-group
                label="Tipo di Nota Spesa *"
                label-for="type"
              >
                <v-select
                  id="type"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="types"
                  @input="setType($event)"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              lg="6"
              class="mb-1"
            >
              <b-form-group
                label="Seleziona Utente *"
                label-for="user_id"
              >
                <v-select
                  id="user_id"
                  :filterable="false"
                  :options="usersPaginated"
                  @open="onOpen"
                  @close="onClose"
                  @search="(query) => (search = query)"
                  @input="selectUser($event)"
                >
                  <template #list-footer>
                    <li
                      v-show="hasNextPage"
                      ref="load"
                      class="loader"
                    >
                      Carica più Utenti...
                    </li>
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              class="mb-1"
            >
              <b-form-group
                label="Oggetto *"
                label-for="subject"
              >
                <b-form-input
                  id="subject"
                  v-model="newExpensenote.subject"
                  type="text"
                  placeholder="Scrivi qui l'Oggetto della Nota Spesa"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
              lg="6"
              class="mb-1"
            >
              <b-form-group
                label="Data Spesa *"
                label-for="date_day"
              >
                <b-form-datepicker
                  id="date_day"
                  v-model="newExpensenote.date_day"
                  placeholder="Scegli Data"
                  locale="it"
                  :min="fromDay"
                  today-button
                  reset-button
                  close-button
                  label-today-button="Oggi"
                  label-reset-button="Annulla"
                  label-close-button="Chiudi"
                  :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                  label-help="Scegli la data della Spesa"
                  label-no-date-selected="Nessuna data selezionata"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="6"
              md="3"
              lg="3"
              class="mb-1"
            >
              <b-form-group
                label="Alle ore *"
                label-for="date_time"
              >
                <b-form-timepicker
                  id="date_time"
                  v-model="newExpensenote.date_time"
                  locale="it"
                  placeholder="Orario"
                  reset-button
                  label-reset-button="Annulla"
                  label-close-button="Chiudi"
                  label-no-time-selected="Seleziona orario"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="6"
              md="3"
              lg="3"
              class="mb-1"
            >
              <b-form-group
                label="Rimborsabile"
                label-for="refundable"
              >
                <b-form-checkbox
                  id="refundable"
                  v-model="newExpensenoteRefundable"
                  switch
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
              lg="6"
              class="mb-1"
            >
              <b-form-group
                label="Importo Spesa *"
                label-for="expense"
              >
                <b-input-group prepend="€">
                  <b-form-input
                    id="expense"
                    v-model="newExpensenote.expense_amount"
                    type="number"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col
              v-if="newExpensenoteRefundable"
              cols="12"
              md="6"
              lg="6"
              class="mb-1"
            >
              <b-form-group
                label="Importo Rimborso *"
                label-for="refund"
              >
                <b-input-group prepend="€">
                  <b-form-input
                    id="refund"
                    v-model="newExpensenote.refund_amount"
                    type="number"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
              lg="6"
              class="mb-1"
            >
              <b-form-group
                label="Valuta *"
                label-for="currency"
              >
                <v-select
                  id="currency"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="currencies"
                  @input="setCurrency($event)"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
              lg="6"
              class="mb-1"
            >
              <b-form-group
                label="Metodo di Pagamento *"
                label-for="payment_method"
              >
                <v-select
                  id="payment_method"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="paymentMethods"
                  @input="setPaymentMethod($event)"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              class="mb-1"
            >
              <b-form-group
                label="Allegati"
                label-for="attachments"
              >
                <b-form-file
                  id="attachments"
                  v-model="attachments"
                  placeholder="Allega Documentazione scegliendo uno o più File o trascinandolo/i qui..."
                  drop-placeholder="Trascina il/i File qui..."
                  multiple
                >
                  <template
                    slot="file-name"
                    slot-scope="{ names }"
                  >
                    <b-badge variant="primary">
                      {{ names[0] }}
                    </b-badge>
                    <b-badge
                      v-if="names[1]"
                      variant="primary"
                      class="ml-1"
                    >
                      {{ names[1] }}
                    </b-badge>
                    <b-badge
                      v-if="names.length > 2"
                      variant="primary"
                      class="ml-1"
                    >
                      + {{ names.length - 2 }} Altri File
                    </b-badge>
                  </template>
                </b-form-file>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              class="mb-1"
            >
              <b-form-group
                label="Descrizione Nota Spesa"
                label-for="description"
              >
                <b-form-textarea
                  id="description"
                  v-model="newExpensenote.description"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </template>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="outline-danger"
          @click="cancel()"
        >
          Annulla
        </b-button>
        <b-button
          variant="success"
          @click="createExpensenote()"
        >
          Conferma
        </b-button>
      </template>
    </b-modal>
    <b-modal
      id="update-modal"
      title="Modifica Nota Spesa"
      size="lg"
      footer-class="d-flex justify-content-end"
      centered
    >
      <template #modal-header="{ close }">
        <h5 class="mb-0">
          Modifica Nota Spesa
        </h5>
        <feather-icon
          icon="XIcon"
          size="18"
          @click="close()"
        />
      </template>
      <template>
        <b-form>
          <b-row class="mt-2 mb-2">
            <b-col
              cols="12"
              lg="6"
              class="mb-1"
            >
              <b-form-group
                label="Tipo di Nota Spesa *"
                label-for="type"
              >
                <v-select
                  id="type"
                  v-model="updatedExpensenoteType"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="types"
                  @input="setUpdatedType($event)"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              lg="6"
              class="mb-1"
            >
              <b-form-group
                label="Seleziona Utente *"
                label-for="user_id"
              >
                <v-select
                  id="user_id"
                  v-model="updatedExpensenoteUser"
                  :filterable="false"
                  :options="usersPaginated"
                  @open="onOpen"
                  @close="onClose"
                  @search="(query) => (search = query)"
                  @input="selectUpdatedUser($event)"
                >
                  <template #list-footer>
                    <li
                      v-show="hasNextPage"
                      ref="load"
                      class="loader"
                    >
                      Carica più Utenti...
                    </li>
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              class="mb-1"
            >
              <b-form-group
                label="Oggetto *"
                label-for="subject"
              >
                <b-form-input
                  id="subject"
                  v-model="updatedExpensenote.subject"
                  type="text"
                  placeholder="Scrivi qui l'Oggetto della Nota Spesa"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
              lg="6"
              class="mb-1"
            >
              <b-form-group
                label="Data Spesa *"
                label-for="date_day"
              >
                <b-form-datepicker
                  id="date_day"
                  v-model="updatedExpensenote.date_day"
                  placeholder="Scegli Data"
                  locale="it"
                  :min="minDay"
                  today-button
                  reset-button
                  close-button
                  label-today-button="Oggi"
                  label-reset-button="Annulla"
                  label-close-button="Chiudi"
                  :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                  label-help="Scegli la data della Spesa"
                  label-no-date-selected="Nessuna data selezionata"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="6"
              md="3"
              lg="3"
              class="mb-1"
            >
              <b-form-group
                label="Alle ore *"
                label-for="date_time"
              >
                <b-form-timepicker
                  id="date_time"
                  v-model="updatedExpensenote.date_time"
                  locale="it"
                  placeholder="Orario"
                  reset-button
                  label-reset-button="Annulla"
                  label-close-button="Chiudi"
                  label-no-time-selected="Seleziona orario"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="6"
              md="3"
              lg="3"
              class="mb-1"
            >
              <b-form-group
                label="Rimborsabile"
                label-for="refundable"
              >
                <b-form-checkbox
                  id="refundable"
                  v-model="updatedExpensenoteRefundable"
                  switch
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
              lg="6"
              class="mb-1"
            >
              <b-form-group
                label="Importo Spesa *"
                label-for="expense"
              >
                <b-input-group prepend="€">
                  <b-form-input
                    id="expense"
                    v-model="updatedExpensenote.expense_amount"
                    type="number"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col
              v-if="updatedExpensenoteRefundable"
              cols="12"
              md="6"
              lg="6"
              class="mb-1"
            >
              <b-form-group
                label="Importo Rimborso *"
                label-for="refund"
              >
                <b-input-group prepend="€">
                  <b-form-input
                    id="refund"
                    v-model="updatedExpensenote.refund_amount"
                    type="number"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              lg="6"
              class="mb-1"
            >
              <b-form-group
                label="Valuta *"
                label-for="currency"
              >
                <v-select
                  id="currency"
                  v-model="updatedExpensenoteCurrency"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="currencies"
                  @input="setUpdatedCurrency($event)"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              lg="6"
              class="mb-1"
            >
              <b-form-group
                label="Metodo di Pagamento *"
                label-for="payment_method"
              >
                <v-select
                  id="payment_method"
                  v-model="updatedExpensenotePaymentMethod"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="paymentMethods"
                  @input="setUpdatedPaymentMethod($event)"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              class="mb-1"
            >
              <b-form-group
                label="Allegati"
                label-for="attachments"
              >
                <b-form-file
                  id="attachments"
                  v-model="attachmentsAdd"
                  placeholder="Allega Documentazione scegliendo uno o più File o trascinandolo/i qui..."
                  drop-placeholder="Trascina il/i File qui..."
                  multiple
                >
                  <template
                    slot="file-name"
                    slot-scope="{ names }"
                  >
                    <b-badge variant="primary">
                      {{ names[0] }}
                    </b-badge>
                    <b-badge
                      v-if="names[1]"
                      variant="primary"
                      class="ml-1"
                    >
                      {{ names[1] }}
                    </b-badge>
                    <b-badge
                      v-if="names.length > 2"
                      variant="primary"
                      class="ml-1"
                    >
                      + {{ names.length - 2 }} Altri File
                    </b-badge>
                  </template>
                </b-form-file>
                <b-button
                  class="mt-1"
                  variant="outline-success"
                  size="sm"
                  @click="addFiles()"
                >
                  Aggiungi Allegati
                </b-button>
              </b-form-group>
            </b-col>
            <b-col
              v-if="updatedAttachments && updatedAttachments.length > 0"
              :key="attachmentsKey"
              cols="12"
              class="mb-2"
            >
              <h6>Allegati:</h6>
              <div class="d-flex flex-wrap mt-1">
                <span
                  v-for="file in updatedAttachments"
                  :key="file.file_path"
                  style="font-size: 16px"
                >
                  <div>
                    <b-badge
                      class="pr-1 pl-1 mb-1"
                      variant="primary"
                      @click="downloadFile(file)"
                    >
                      <feather-icon icon="PaperclipIcon" />
                      {{ file.name }}
                    </b-badge>
                    <feather-icon
                      size="22"
                      class="bg-danger rounded mr-2 delete-badge"
                      icon="XIcon"
                      @click="deleteFile(file)"
                    />
                  </div>
                </span>
              </div>
            </b-col>
            <b-col
              cols="12"
              class="mb-1"
            >
              <b-form-group
                label="Descrizione Nota Spesa"
                label-for="description"
              >
                <b-form-textarea
                  id="description"
                  v-model="updatedExpensenote.description"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </template>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="outline-danger"
          @click="cancel()"
        >
          Annulla
        </b-button>
        <b-button
          variant="success"
          @click="updateExpensenote()"
        >
          Conferma
        </b-button>
      </template>
    </b-modal>
    <b-modal
      id="delete-modal"
      title="Cancella Nota Spesa"
      footer-class="d-flex justify-content-end"
      body-class="p-0"
      size="sm"
      centered
    >
      <template #modal-header="{ close }">
        <h5 class="mb-0">
          Cancella Nota Spesa
        </h5>
        <feather-icon
          icon="XIcon"
          size="18"
          @click="close()"
        />
      </template>
      <p class="m-2">
        Sei sicuro di voler cancellare definitivamente questa Nota Spesa e i suoi dati correlati?
      </p>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          size="sm"
          variant="danger"
          @click="cancel()"
        >
          Annulla
        </b-button>
        <b-button
          size="sm"
          variant="success"
          @click="
            ok()
            deleteExpensenote(selectedExpensenote)
          "
        >
          OK
        </b-button>
      </template>
    </b-modal>
    <b-modal
      id="export-modal"
      title="Esporta Note Spesa"
      footer-class="d-flex justify-content-end"
      centered
    >
      <template #modal-header="{ close }">
        <h5 class="mb-0">
          Esporta Note Spesa
        </h5>
        <feather-icon
          icon="XIcon"
          size="18"
          @click="close()"
        />
      </template>
      <template>
        <b-form>
          <b-row class="mt-2 mb-2">
            <b-col
              cols="12"
              class="mb-1"
            >
              <b-form-group
                label="Utente"
                label-for="type"
              >
                <v-select
                  id="user_id"
                  :filterable="false"
                  :options="usersPaginated"
                  @open="onOpen"
                  @close="onClose"
                  @search="(query) => (search = query)"
                  @input="selectUserCsv($event)"
                >
                  <template #list-footer>
                    <li
                      v-show="hasNextPage"
                      ref="load"
                      class="loader"
                    >
                      Carica più Utenti...
                    </li>
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
              lg="6"
              class="mb-1"
            >
              <b-form-group
                label="Dal"
                label-for="date_from"
              >
                <b-form-datepicker
                  id="date_from"
                  v-model="newExport.date_from"
                  placeholder="Scegli Data"
                  locale="it"
                  today-button
                  reset-button
                  close-button
                  label-today-button="Oggi"
                  label-reset-button="Annulla"
                  label-close-button="Chiudi"
                  :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                  label-help="Scegli la data di inizio della selezione"
                  label-no-date-selected="Nessuna data selezionata"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
              lg="6"
              class="mb-1"
            >
              <b-form-group
                label="Al"
                label-for="date_to"
              >
                <b-form-datepicker
                  id="date_to"
                  v-model="newExport.date_to"
                  placeholder="Scegli Data"
                  locale="it"
                  today-button
                  reset-button
                  close-button
                  label-today-button="Oggi"
                  label-reset-button="Annulla"
                  label-close-button="Chiudi"
                  :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                  label-help="Scegli la data di fine della selezione"
                  label-no-date-selected="Nessuna data selezionata"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </template>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="outline-danger"
          @click="cancel()"
        >
          Annulla
        </b-button>
        <b-button
          variant="success"
          @click="exportCsv()"
        >
          Esporta
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BCarousel,
  BCarouselSlide,
  BAvatar,
  BLink,
  BCardTitle,
  BButton,
  BCollapse,
  BCardText,
  BFormTextarea,
  BForm,
  BFormSelect,
  BFormGroup,
  BFormDatepicker,
  BFormTimepicker,
  BFormCheckbox,
  BFormRadio,
  BFormFile,
  BFormInput,
  BInputGroup,
  BBadge,
  VBToggle,
} from 'bootstrap-vue'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import userStoreModule from '@/views/apps/user/userStoreModule'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment'
import axios from '@axios'
import expensenotesStoreModule from '../expensenotesStoreModule'
import useExpensenotesList from './useExpensenotesList'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BCarousel,
    BCarouselSlide,
    BAvatar,
    BLink,
    BCardTitle,
    BButton,
    BCollapse,
    BCardText,
    BFormTextarea,
    BForm,
    BFormSelect,
    BFormGroup,
    BFormDatepicker,
    BFormTimepicker,
    BFormCheckbox,
    BFormRadio,
    BFormFile,
    BFormInput,
    BInputGroup,
    BBadge,
    vSelect,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  setup() {
    const EXPENSENOTES_APP_STORE_MODULE_NAME = 'app-expensenotes'
    const USER_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    if (!store.hasModule(EXPENSENOTES_APP_STORE_MODULE_NAME)) {
      store.registerModule(EXPENSENOTES_APP_STORE_MODULE_NAME, expensenotesStoreModule)
    }
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) {
      store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(EXPENSENOTES_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(EXPENSENOTES_APP_STORE_MODULE_NAME)
      }
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const months = [
      'Gennaio',
      'Febbraio',
      'Marzo',
      'Aprile',
      'Maggio',
      'Giugno',
      'Luglio',
      'Agosto',
      'Settembre',
      'Ottobre',
      'Novembre',
      'Dicembre',
    ]
    const momentMonth = moment().format('M')
    const currentMonth = momentMonth - 1
    const previousMonth = momentMonth - 1
    const currentDate = moment()
    const initialMonth = currentDate.month() // Ottieni il mese corrente (0-11)
    const initialYear = currentDate.year()
    const fromDay = currentDate.startOf('month').format('YYYY-MM-DD')
    const toDay = currentDate.endOf('month').format('YYYY-MM-DD')

    const expensenotes = ref(null)
    store
      .dispatch('app-expensenotes/fetchExpensenotes', {
        type: null,
        sort_by: 'created_at',
        sort_direction: 'DESC',
        from_day: fromDay,
        to_day: toDay,
      })
      .then(response => {
        expensenotes.value = response.data[0]
      })
      .catch(error => {
        if (error === 401) {
          localStorage.removeItem('userData')
          store.commit('user/updateUserData', null)
          window.location.reload(true)
        }
      })

    const mediaEndpoint = process.env.VUE_APP_MEDIA_URL

    const {
      types,
      users,
      currencies,
      currencyLabels,
      paymentMethods,
      paymentMethodLabels,
      resolveUserRoleName,
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      expensenotesStatus,
      expensenotesStatusColor,
      expensenotesStatusIcon,
      parseDate,
      parseCurrency,
    } = useExpensenotesList()

    return {
      avatarText,
      resolveUserRoleName,
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      expensenotesStatus,
      expensenotesStatusColor,
      expensenotesStatusIcon,
      parseDate,
      parseCurrency,

      loadingCarousel: false,
      months,
      currentMonth,
      previousMonth,
      currentYear: initialYear,
      minDay: null,
      fromDay,
      toDay,
      types,
      users,
      currencies,
      currencyLabels,
      paymentMethods,
      paymentMethodLabels,
      observer: null,
      limit: 10,
      search: '',
      selectedMonth: initialMonth,
      mediaEndpoint,
      loading: false,
      selectedExpensenote: null,
      attachments: [],
      attachmentsAdd: [],
      updatedAttachments: [],
      expensenotes,
      filterType: null,
      listKey: 0,
      attachmentsKey: 0,
      updatedExpensenote: {
        type: null,
        user_id: null,
        subject: null,
        description: null,
        expense_amount: null,
        refund_amount: null,
        date_day: null,
        date_time: null,
        currency: null,
        payment_method: null,
        refundable: true,
      },
      updatedExpensenoteUser: null,
      updatedExpensenoteType: null,
      updatedExpensenoteCurrency: null,
      updatedExpensenotePaymentMethod: null,
      updatedExpensenoteRefundable: null,
      updatedAttachments: null,
      newExpensenoteRefundable: true,
      newExpensenote: {
        type: null,
        user_id: null,
        subject: null,
        description: null,
        expense_amount: null,
        refund_amount: null,
        date_day: null,
        date_time: null,
        currency: null,
        payment_method: null,
        refundable: true,
      },
      newExport: {
        user_id: null,
        date_from: null,
        date_to: null,
      },
    }
  },
  computed: {
    usersFiltered() {
      return this.users.filter(data => JSON.stringify(data).toLowerCase().indexOf(this.search.toLowerCase()) !== -1)
    },
    usersPaginated() {
      return this.usersFiltered.slice(0, this.limit)
    },
    hasNextPage() {
      return this.usersPaginated.length < this.usersFiltered.length
    },
  },
  mounted() {
    this.$refs.monthSlider.setSlide(this.currentMonth)
    this.loadingCarousel = true
    this.observer = new IntersectionObserver(this.infiniteScroll)
  },
  methods: {
    refetchExpensenotes() {
      store
        .dispatch('app-expensenotes/fetchExpensenotes', {
          type: this.filterType ? this.filterType.value : null,
          sort_by: 'created_at',
          sort_direction: 'DESC',
          from_day: this.fromDay,
          to_day: this.toDay,
        })
        .then(response => {
          this.expensenotes = response.data[0]
        })
        .catch(error => {
          if (error === 401) {
            localStorage.removeItem('userData')
            store.commit('user/updateUserData', null)
            router.replace({ name: 'auth-login' }).then(() => {
              toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Sessione scaduta',
                  icon: 'AlertTriangleIcon',
                  text: "Sessione scaduta, effettuare di nuovo l'accesso per utilizzare tutte le funzionalità",
                  variant: 'danger',
                },
              })
            })
          }
        })
      this.listKey++
    },
    async onOpen() {
      if (this.hasNextPage) {
        await this.$nextTick()
        this.observer.observe(this.$refs.load)
      }
    },
    onClose() {
      this.observer.disconnect()
    },
    async infiniteScroll([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent
        const { scrollTop } = target.offsetParent
        this.limit += 10
        await this.$nextTick()
        ul.scrollTop = scrollTop
      }
    },
    setType(e) {
      this.newExpensenote.type = e ? e.value : null
    },
    setUpdatedType(e) {
      this.updatedExpensenote.type = e ? e.value : null
    },
    setCurrency(e) {
      this.newExpensenote.currency = e ? e.value : null
    },
    setUpdatedCurrency(e) {
      this.updatedExpensenote.currency = e ? e.value : null
    },
    setPaymentMethod(e) {
      this.newExpensenote.payment_method = e ? e.value : null
    },
    setUpdatedPaymentMethod(e) {
      this.updatedExpensenote.payment_method = e ? e.value : null
    },
    selectUser(e) {
      this.newExpensenote.user_id = e ? e.value : null
    },
    selectUserCsv(e) {
      this.newExport.user_id = e ? e.value : null
    },
    selectUpdatedUser(e) {
      this.updatedExpensenote.user_id = e ? e.value : null
    },
    createExpensenote() {
      if (this.newExpensenote.date_time == '') this.newExpensenote.date_time = null
      const valid = this.validation('create')
      if (!valid) return
      this.loading = true
      const formData = new FormData()
      formData.append('type', this.newExpensenote.type)
      formData.append('user_id', this.newExpensenote.user_id)
      formData.append('subject', this.newExpensenote.subject)
      formData.append('expense_amount', this.newExpensenote.expense_amount)
      formData.append('refund_amount', this.newExpensenote.refund_amount)
      formData.append('date_day', this.newExpensenote.date_day)
      formData.append('date_time', this.newExpensenote.date_time)
      formData.append('currency', this.newExpensenote.currency)
      formData.append('payment_method', this.newExpensenote.payment_method)
      formData.append('refundable', this.newExpensenoteRefundable === true ? 1 : 0)
      if (this.newExpensenote.description) formData.append('description', this.newExpensenote.description)
      if (this.attachments.length > 0) {
        for (let i = 0; i < this.attachments.length; i++) {
          const attachment = this.attachments[i]
          formData.append('attachments', attachment)
        }
      }
      store
        .dispatch('app-expensenotes/addExpensenote', formData)
        .then(result => {
          this.refetchExpensenotes()
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `Nota Spesa #${result.data.id} aggiunta con successo`,
              icon: 'DollarSignIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          if (error === 401) {
            localStorage.removeItem('userData')
            store.commit('user/updateUserData', null)
            window.location.reload(true)
          }
        })
      this.loading = false
      this.$bvModal.hide('create-modal')
    },
    updateExpensenote() {
      if (this.updatedExpensenote.date_time == '') this.updatedExpensenote.date_time = null
      const valid = this.validation('update')
      if (!valid) return
      this.loading = true
      const { id } = this.updatedExpensenote
      const formData = {
        type: this.updatedExpensenote.type,
        user_id: this.updatedExpensenote.user_id,
        subject: this.updatedExpensenote.subject,
        expense_amount: this.updatedExpensenote.expense_amount,
        refund_amount: this.updatedExpensenote.refund_amount,
        date_day: this.updatedExpensenote.date_day,
        date_time: this.updatedExpensenote.date_time,
        description: this.updatedExpensenote.description,
        currency: this.updatedExpensenote.currency,
        payment_method: this.updatedExpensenote.payment_method,
        refundable: this.updatedExpensenoteRefundable === true ? 1 : 0,
      }
      store
        .dispatch('app-expensenotes/updateExpensenote', { id, formData })
        .then(result => {
          this.refetchExpensenotes()
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Nota Spesa modificata con successo',
              icon: 'DollarSignIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          if (error === 401) {
            localStorage.removeItem('userData')
            store.commit('user/updateUserData', null)
            window.location.reload(true)
          }
        })
      this.loading = false
      this.$bvModal.hide('update-modal')
    },
    deleteExpensenote(id) {
      store
        .dispatch('app-expensenotes/deleteExpensenote', { id })
        .then(() => {
          this.selectedExpensenote = null
          this.refetchExpensenotes()
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `Nota Spesa #${id} eliminata con successo`,
              icon: 'DollarSignIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          if (error === 401) {
            localStorage.removeItem('userData')
            store.commit('user/updateUserData', null)
            window.location.reload(true)
          }
        })
    },
    downloadFile(file) {
      const url = `/media/file/${file.file_path}`
      axios.get(url, { responseType: 'blob' })
        .then(response => {
          const blob = new Blob([response.data], { type: file.file_type })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = file.name
          link.click()
          URL.revokeObjectURL(link.href)
        }).catch(console.error)
    },
    addFiles() {
      const formData = new FormData()
      formData.append('expense_note_id', this.updatedExpensenote.id)
      if (this.attachmentsAdd.length > 0) {
        for (let i = 0; i < this.attachmentsAdd.length; i++) {
          const attachment = this.attachmentsAdd[i]
          formData.append('attachments', attachment)
        }
      }
      store
        .dispatch('app-expensenotes/addExpensenoteAttachments', formData)
        .then(() => {
          this.refetchUpdatedExpensenote()
          this.refetchExpensenotes()
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Allegato/i aggiunto/i con successo',
              icon: 'DollarSignIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          if (error === 401) {
            localStorage.removeItem('userData')
            store.commit('user/updateUserData', null)
            window.location.reload(true)
          }
        })
    },
    deleteFile(file) {
      const { id } = file
      store
        .dispatch('app-expensenotes/deleteExpensenoteAttachment', { id })
        .then(() => {
          this.refetchUpdatedExpensenote()
          this.refetchExpensenotes()
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Allegato eliminato con successo',
              icon: 'DollarSignIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          if (error === 401) {
            localStorage.removeItem('userData')
            store.commit('user/updateUserData', null)
            window.location.reload(true)
          }
        })
    },
    refetchUpdatedExpensenote() {
      const { id } = this.updatedExpensenote
      store
        .dispatch('app-expensenotes/fetchExpensenote', { id })
        .then(result => {
          this.updatedExpensenoteUser = { label: `#${result.data.user.anagrafico_id} - ${result.data.user.display_name}`, value: result.data.user.id }
          this.updatedExpensenoteType = { label: result.data.type.charAt(0).toUpperCase() + result.data.type.slice(1), value: result.data.type }
          this.updatedAttachments = result.data.attachments
          this.updatedExpensenote = result.data
        })
        .catch(error => {
          if (error === 401) {
            localStorage.removeItem('userData')
            store.commit('user/updateUserData', null)
            window.location.reload(true)
          }
        })
      this.attachmentsKey++
    },
    getPreviousMonth() {
      if (this.selectedMonth) this.previousMonth = this.selectedMonth
    },
    handleSlideStart() {
      // Verifica se si sta scorrendo all'indietro (frecce sinistra)
      if (this.$refs.monthSlider.direction === 'prev') {
        if (this.selectedMonth === 0) {
          // Riduci l'anno se sei a gennaio
          this.currentYear--
          this.selectedMonth = 11 // Imposta il mese a dicembre
        } else {
          this.selectedMonth--
        }
      }
      // Verifica se si sta scorrendo in avanti (frecce destra)
      else if (this.$refs.monthSlider.direction === 'next') {
        if (this.selectedMonth === 11) {
          // Aumenta l'anno se sei a dicembre
          this.currentYear++
          this.selectedMonth = 0 // Imposta il mese a gennaio
        } else {
          this.selectedMonth++
        }
      }

      // Aggiorna le variabili di data in base al mese e all'anno correnti
      const fromDate = moment([this.currentYear, this.selectedMonth])
      const toDate = fromDate.clone().endOf('month')
      this.fromDay = fromDate.format('YYYY-MM-DD')
      this.toDay = toDate.format('YYYY-MM-DD')
      this.refetchExpensenotes()
    },
    exportCsv() {
      if (this.newExport.date_from == '') this.newExport.date_from = null
      if (this.newExport.date_to == '') this.newExport.date_to = null
      store
        .dispatch('app-expensenotes/exportCsv', {
          sort_by: 'created_at',
          sort_direction: 'DESC',
          from_day: this.newExport.date_from,
          to_day: this.newExport.date_to,
          user_id: this.newExport.user_id,
        })
        .then(response => {
          const now = moment().format('DD-MM-YYYY_HH-mm-ss')
          const blob = new Blob([response.data], {
            type: 'text/csv',
          })
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = `${now}_note-spesa.csv`
          link.click()
        })
        .catch(error => {
          if (error === 401) {
            localStorage.removeItem('userData')
            store.commit('user/updateUserData', null)
            router.replace({ name: 'auth-login' }).then(() => {
              toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Sessione scaduta',
                  icon: 'AlertTriangleIcon',
                  text: "Sessione scaduta, effettuare di nuovo l'accesso per utilizzare tutte le funzionalità",
                  variant: 'danger',
                },
              })
            })
          }
        })
    },
    validation(type) {
      let object = this.newExpensenote
      let refundable = this.newExpensenoteRefundable
      if (type == 'update') object = this.updatedExpensenote
      if (type == 'update') refundable = this.updatedExpensenoteRefundable
      if (!object.type) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Errore Tipo',
            icon: 'AlertTriangleIcon',
            text: 'Inserire il tipo di Nota Spesa per proseguire.',
            variant: 'danger',
          },
        })
        return false
      }
      if (!object.user_id) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Errore Utente',
            icon: 'AlertTriangleIcon',
            text: "Selezionare l'utente della Nota Spesa per proseguire.",
            variant: 'danger',
          },
        })
        return false
      }
      if (!object.subject) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Errore Oggetto',
            icon: 'AlertTriangleIcon',
            text: "Inserire l'Oggetto della Nota Spesa per proseguire.",
            variant: 'danger',
          },
        })
        return false
      }
      if (!object.date_day) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Errore Data',
            icon: 'AlertTriangleIcon',
            text: 'Selezionare la data della Nota Spesa per proseguire.',
            variant: 'danger',
          },
        })
        return false
      }
      if (!object.date_time) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Errore Ora',
            icon: 'AlertTriangleIcon',
            text: "Selezionare l'ora della Nota Spesa per proseguire.",
            variant: 'danger',
          },
        })
        return false
      }
      if (!object.expense_amount) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Errore Spesa',
            icon: 'AlertTriangleIcon',
            text: 'Inserire la Spesa della Nota Spesa per proseguire.',
            variant: 'danger',
          },
        })
        return false
      }
      if (refundable && !object.refund_amount) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Errore Rimborso',
            icon: 'AlertTriangleIcon',
            text: 'Inserire il Rimborso della Nota Spesa per proseguire.',
            variant: 'danger',
          },
        })
        return false
      }
      if (!object.currency) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Errore Valuta',
            icon: 'AlertTriangleIcon',
            text: 'Inserire la Valuta della Nota Spesa per proseguire.',
            variant: 'danger',
          },
        })
        return false
      }
      if (!object.payment_method) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Errore Metodo di Pagamento',
            icon: 'AlertTriangleIcon',
            text: 'Inserire il Metodo di Pagamento della Nota Spesa per proseguire.',
            variant: 'danger',
          },
        })
        return false
      }
      return true
    },
  },
}
</script>

<style lang="scss">
#month-slider,
.carousel-inner,
.carousel-item {
  background: transparent !important;
  height: 30px;
}
#month-slider .carousel-item > img {
  height: 100%;
}

#month-slider .carousel-caption {
  bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
  h2 {
    margin-bottom: 0;
  }
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: 'Sfoglia';
}
</style>
<style lang="scss" scoped>
.card-footer {
  text-align: center;
  padding: 5px;
}
.rounded-lg textarea.form-control {
  background-color: #161d31;
  border-color: #161d31;
  border-radius: 30px;
  padding: 1rem;
}
.rounded-lg textarea.form-control:not(:focus) {
  border-color: #161d31;
}
.loader {
  text-align: center;
  color: #bbbbbb;
}
.badge:hover {
  cursor: pointer;
}
.delete-badge:hover {
  cursor: pointer;
}
</style>
